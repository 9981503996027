import React from "react";
import Step1 from "./step/step-1";
import logo from "../assets/img/foto_apply.jpg";
import Countries from "../Countries.json";
import swal from "sweetalert";
import * as dateFns from "date-fns";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData,
} from "../assets/js/utils.js";

class VersionOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getModalState();

    this.ErrorMessage = this.ErrorMessage.bind(this);
    this.step1Ref = React.createRef();
  }

  getModalState = () => {
    return {
      Semesters: [],
      ApplicationObj: {
        IsInitialAttendance: IsInitialAttendance,
        Step1: {
          ApplicationChoiceId: 1,

          PersonalInformation: {
            FullName: "",
            Email: "",
            InvalidEmail: false,
            PhoneNumber: "",
          },
          FileContent: null,
          FileName: "",

          AddressInside: {
            Street: "",
            Street2: "",
            City: "",
            State: "",
            Zip: "",
            CountryText: "United States",
            Country: "US",
          },
          EmergencyContact: {
            FullName: "",
            Relationship: "",
            PhoneNumber: "",
          },

          SemesterId: -1,
          CampusId: -1,
          PeriodId: -1,

          trimmedDataURL: null,
          ModalIsOpen: false,
          ErrorSubmit: "",
          Agree1: false,
          Agree2: false,

          SubmittingApplication: false,
        },
      },
    };
  };

  componentDidMount() {
    const { pathname } = window.location;

    this._refreshSemesters().then(() => {
      if (pathname.endsWith("/transfer")) {
        this._setApplicationChoice(2).then(() => {
          if (this.step1Ref.current) {
            this.step1Ref.current.simulateNextClick();
          }
        });
      }
    });
  }

  render() {
    return (
      <div className="wrapper">
        <div className="steps-area steps-area-fixed">
          <div className="image-holder">
            <img src={logo} alt="" />
          </div>
          <div className="steps clearfix">
            <ul className="tablist multisteps-form__progress">
              <li className="multisteps-form__progress-btn js-active current">
                <span>1</span>
              </li>
            </ul>
          </div>
        </div>
        <form
          className="multisteps-form__form"
          action="#"
          id="wizard"
          method="POST"
        >
          <div className="form-area position-relative">
            <Step1
              data={this.state.ApplicationObj.Step1}
              step1SemesterRender={this.step1SemesterRender.bind(this)}
              step1CampusRender={this.step1CampusRender.bind(this)}
              step1PeriodRender={this.step1PeriodRender.bind(this)}
              onFileChange={this.onFileChange}
              applicationChoiceId={
                this.state.ApplicationObj.Step1.ApplicationChoiceId
              }
              Semesters={this.state.Semesters}
              handleUserInput={this.handleUserInput.bind(this)}
              emailOnBlur={this.emailOnBlur.bind(this)}
              handleSelectChange={this.handleSelectChange.bind(this)}
              Countries={Countries}
              handleCheckbox={this.handleCheckbox.bind(this)}
              handleSubmit={this.handleSubmit.bind(this)}
              sigPad={this.sigPad}
              clear={this.clear}
              trim={this.trim}
              getApplicationSemester={this.getApplicationSemester.bind(this)}
              getApplicationCampus={this.getApplicationCampus.bind(this)}
              getApplicationPeriod={this.getApplicationPeriod.bind(this)}
              handleSubmit={this.handleSubmit.bind(this)}
              handleCheckbox={this.handleCheckbox.bind(this)}
            />

            {/* <Step2
              data={this.state.ApplicationObj.Step2}
              applicationObj={this.state.ApplicationObj}
              handleUserInput={this.handleUserInput.bind(this)}
              ref={this.step1Ref}
            /> */}

            {/* <Step3
              data={this.state.ApplicationObj.Step3}
              handleSelectChange={this.handleSelectChange.bind(this)}
              handleUserInput={this.handleUserInput.bind(this)}
              Countries={Countries}
              onFileChange={this.onFileChange}
              applicationChoiceId={
                this.state.ApplicationObj.Step1.ApplicationChoiceId
              }
              emailOnBlur={this.emailOnBlur.bind(this)}
            /> */}

            {/* <Step5
              data={this.state.ApplicationObj.Step5}
              handleSelectChange={this.handleSelectChange.bind(this)}
              handleUserInput={this.handleUserInput.bind(this)}
              onFileChange={this.onFileChange}
              handleDependentChange={this.handleDependentChange.bind(this)}
              applicationChoiceId={
                this.state.ApplicationObj.Step1.ApplicationChoiceId
              }
              onFileChangeDependent={this.onFileChangeDependent}
              handleHasDependentSelectChange={
                this.handleHasDependentSelectChange
              }
            />
            <Step6
              data={this.state.ApplicationObj.Step6}
              step1SemesterRender={this.step1SemesterRender.bind(this)}
              onFileChange={this.onFileChange}
              sigPad={this.sigPad}
              clear={this.clear}
              trim={this.trim}
              toggleModal={this.toggleModal}
              getApplicationChoiceName={this.getApplicationChoiceName.bind(
                this
              )}
              getApplicationSemester={this.getApplicationSemester.bind(this)}
              getApplicationCampus={this.getApplicationCampus.bind(this)}
              getApplicationPeriod={this.getApplicationPeriod.bind(this)}
              handleSubmit={this.handleSubmit.bind(this)}
              ApplicationObj={this.state.ApplicationObj}
              handleCheckbox={this.handleCheckbox.bind(this)}
              applicationChoiceId={
                this.state.ApplicationObj.Step1.ApplicationChoiceId
              }
              toggleModalPayment={this.toggleModalPayment.bind(this)}
              pay={this.pay}
              handleUserInput={this.handleUserInput.bind(this)}
              handleCardInputChange={this.handleCardInputChange}
              handleInputFocus={this.handleInputFocus}
            /> */}
          </div>
        </form>
      </div>
    );
  }

  step1SemesterRender = (id) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let Step1 = ApplicationObj.Step1;

        Step1.SemesterId = id;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  step1CampusRender = (id) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let Step1 = ApplicationObj.Step1;

        Step1.CampusId = id;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  step1PeriodRender = (id) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let Step1 = ApplicationObj.Step1;

        Step1.PeriodId = id;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  onFileChange = (parent, fileContent, fileName, event) => {
    let file = event.target.files[0];

    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj[parent];

        if (file !== undefined) {
          let fsize = file.size;
          let total = Math.round(fsize / 1024);

          if (total > 20480) {
            swal(
              "File size error",
              "'" +
                file.name +
                "'" +
                " file size exceeds maximum limit. Maximum allowed file size is 20 MB.",
              "error"
            );
          } else {
            obj[fileContent] = file;
            obj[fileName] = file.name;
          }
        }

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  handleUserInput = (step, parent, e) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj[step];

        obj[parent][e.target.name] = e.target.value;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  handleSelectChange = (step, parent, name, event) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj[step];

        obj[parent][name] = event.target.value;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  clear = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        (prevState) => {
          let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
          let obj = ApplicationObj.Step1;

          obj.trimmedDataURL = null;

          return { ApplicationObj };
        },

        resolve(true)
      );
    });
  };

  trim = (canvas) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj.Step1;

        obj.trimmedDataURL = canvas;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  getApplicationSemester() {
    let id = this.state.ApplicationObj.Step1.SemesterId;

    let semester = this.state.Semesters.find((x) => x.sequential == id);

    return semester && semester.name;
  }

  getApplicationCampus() {
    let id = this.state.ApplicationObj.Step1.CampusId;

    return id === 1 ? "Salt Lake City" : id === 2 ? "Orem" : "";
  }

  getApplicationPeriod() {
    let id = this.state.ApplicationObj.Step1.PeriodId;

    return id === 1
      ? "Tuesday Thursday Morning"
      : id === 2
      ? "Tuesday Thursday Evening"
      : id === 3
      ? "Saturday Morning"
      : "";
  }

  isSubmitting(bool) {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj.Step1;

        obj.SubmittingApplication = bool;

        return { ApplicationObj };
      }, resolve(true));
    });
  }

  setSubmitError(msg) {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj.Step1;

        obj.ErrorSubmit = msg;

        return { ApplicationObj };
      }, resolve(true));
    });
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
  }

  async fillFile(applyObj, name) {
    if (
      applyObj["File" + name] !== null &&
      applyObj["File" + name] !== undefined
    ) {
      let base64File = await this.getBase64(applyObj["File" + name]);
      let fileName = applyObj["File" + name].name;

      applyObj["Document" + name] = {
        FileContentBase64: base64File,
        FileName: fileName,
      };

      applyObj["File" + name] = null;
    }
  }

  finishSubmit() {
    this.isSubmitting(true).then(async () => {
      const self = this;

      let stateObj = Object.assign({}, this.state.ApplicationObj);

      let applyObj = {
        ApplicationChoice: "Swift",

        Semester: this.getApplicationSemester(),
        Campus: this.getApplicationCampus(),
        Period: this.getApplicationPeriod(),

        PersonalFullName: stateObj.Step1.PersonalInformation.FullName,
        PersonalEmail: stateObj.Step1.PersonalInformation.Email,
        PersonalPhoneNumber: stateObj.Step1.PersonalInformation.PhoneNumber,

        AddressInStreet: stateObj.Step1.AddressInside.Street,
        AddressInStreet2: stateObj.Step1.AddressInside.Street2,
        AddressInCity: stateObj.Step1.AddressInside.City,
        AddressInState: stateObj.Step1.AddressInside.State,
        AddressInZip: stateObj.Step1.AddressInside.Zip,
        AddressInCountry: stateObj.Step1.AddressInside.Country,

        EmergencyFullName: stateObj.Step1.EmergencyContact.FullName,
        EmergencyRelationship: stateObj.Step1.EmergencyContact.Relationship,
        EmergencyPhoneNumber: stateObj.Step1.EmergencyContact.PhoneNumber,

        FilePassport: this.state.ApplicationObj.Step1.FileContent,

        IsSwift: true,

        DocumentSignature: {
          FileContentBase64: this.state.ApplicationObj.Step1.trimmedDataURL,
          FileName: "Signature.jpg",
        },
      };

      const isDevelopment = false;
      const url = isDevelopment
        ? "http://localhost:58794"
        : "https://api.lumos.edu";

      await this.fillFile(applyObj, "Passport").then(() => {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(applyObj),
        };

        return fetch(url + "/apply", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            self.isSubmitting(false).then(() => {
              console.log(data);

              if (data.success === true) {
                alert("Thank you for submitting your application.");
                window.location.assign("https://lumos.edu");
              } else {
                let message = data.messages
                  ? data.messages.map((error) => error).join("\n")
                  : "An internal server error has occurred.";

                self.setSubmitError(message);
              }
            });
          })
          .catch(function (error) {
            self.isSubmitting(false).then(() => {
              let message =
                "A server connection error occurred in your application. Error message: " +
                self.ErrorMessage(error);

              self.setSubmitError(message);
            });
          });
      });
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const self = this;

    self.finishSubmit();
  }

  handleCheckbox = (index, e) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let Step1 = ApplicationObj.Step1;

        Step1["Agree" + index] = !Step1["Agree" + index];

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  ErrorMessage(error) {
    var message = "";

    if (error.response) {
      if (error.response.data) {
        if (error.response.data.messages) {
          message = error.response.data.messages
            .map((error) => error)
            .join("\n");
        } else {
          message = error.response.data;
        }
      } else {
        message = error.message;
      }
    } else if (error.message) {
      message = error.message;
    }

    return message;
  }

  emailIsValid(email) {
    return (
      email.length > 0 && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    );
  }

  emailOnBlur = async (e) => {
    const value = e.target.value;

    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let Step1 = ApplicationObj.Step1;

        Step1.PersonalInformation.InvalidEmail = !this.emailIsValid(value);

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  _refreshSemesters = () => {
    const requestOptions = {
      crossDomain: true,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const isDevelopment = false;
    const url = isDevelopment
      ? "http://localhost:58794"
      : "https://api.lumos.edu";

    return fetch(url + "/semesters/?page=1&pageSize=999999", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        return new Promise((resolve, reject) => {
          let semesters = [];

          data.data.items
            .filter((x) => dateFns.parseISO(x.endDate) >= new Date())
            .sort(function (a, b) {
              return new Date(a.startDate) - new Date(b.startDate);
            })
            .map((sem, index) =>
              semesters.push({
                id: sem.id,
                name: sem.name,
                startDate: dateFns.parseISO(sem.startDate),
                endDate: dateFns.parseISO(sem.endDate),
                sequential: index,
              })
            );

          this.setState({ Semesters: semesters }, resolve(true));
        });
      });
  };
}

const IsInitialAttendance = true;

export default VersionOne;
