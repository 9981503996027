import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/bootstrap-datepicker.css";
import "./assets/css/fontawesome-all.css";
import "./assets/css/animate.min.css";
import "react-credit-cards-2/es/styles-compiled.css";

import "./assets/scss/style.scss";

import "bootstrap";

// import "./assets/js/bootstrap-datepicker.min.js";
// import "./assets/js/jquery.validate.min.js";

import "./assets/js/main.js";

const app = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
